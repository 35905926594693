@font-face {
  font-family: 'FatFrank';
  src: url('fonts/fat-frank/FatFrankHeavyRegular.ttf');
}

@font-face {
  font-family: 'ObjektivMk1_Trial_XBd';
  font-style: normal;
  src: url(fonts/ObjektivMK1Trial/ObjektivMk1_Trial_XBd.woff) format('woff');
}

@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/futura-std/FuturaStdBook.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 400;
  src: url(fonts/futura-std/FuturaStdBookOblique.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 300;
  src: url(fonts/futura-std/FuturaStdLight.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 300;
  src: url(fonts/futura-std/FuturaStdLightOblique.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 500;
  src: url(fonts/futura-std/FuturaStdMedium.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 500;
  src: url(fonts/futura-std/FuturaStdMediumOblique.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 650;
  src: url(fonts/futura-std/FuturaStdHeavy.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 650;
  src: url(fonts/futura-std/FuturaStdHeavyOblique.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 700;
  src: url(fonts/futura-std/FuturaStdBold.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 700;
  src: url(fonts/futura-std/FuturaStdBoldOblique.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: normal;
  font-weight: 750;
  src: url(fonts/futura-std/FuturaStdExtraBold.woff) format('woff');
}
@font-face {
  font-family: 'Futura Std';
  font-style: italic;
  font-weight: 750;
  src: url(fonts/futura-std/FuturaStdExtraBoldOblique.woff) format('woff');
}
